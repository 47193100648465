import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { setCurrentAction } from "../../redux/slices/actionsSlice";
import { useDrag } from "@use-gesture/react";
import {Button, Modal, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {setNotifyOpen, setNotifyText, setNotifyType} from "../../redux/slices/notifySlice";

interface Position {
    x: number;
    y: number;
}

interface ModalMenuProps {
    children: React.ReactNode;
    modalTitle: any
}

const ModalMenu: React.FC<ModalMenuProps> = ({ children, modalTitle }) => {
    const dispatch = useDispatch();

    const [position, setPosition] = useState<Position>({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

    const handleModalClose = () => {
        dispatch(setCurrentAction(""));
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(setNotifyText('Збережено'))
        dispatch(setNotifyType('success'));
        dispatch(setNotifyOpen(true))
    };

    const bind = useDrag(({ event, delta: [dx, dy] }) => {
        event.preventDefault();
        setPosition((prevPosition) => ({
            x: prevPosition.x + dx,
            y: prevPosition.y + dy,
        }));
    });

    useEffect(() => {
        const handleResize = () => {
            const centerPosition: Position = {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            };
            setPosition(centerPosition);
        };

        const handleModalOpenWithCenterPosition = () => {
            const centerPosition: Position = {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            };
            setPosition(centerPosition);
        };

        window.addEventListener("resize", handleResize);
        handleModalOpenWithCenterPosition();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <React.Fragment>
            <Modal open={true} onClose={handleModalClose}>
                <Box
                    sx={{
                        width: "70%",
                        backgroundColor: "rgba(52,58,64,1)",
                        position: "fixed",
                        top: position.y,
                        left: position.x,
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="center"
                        sx={{touchAction: 'none'}}
                        {...bind()}
                    >
                        <Box display="flex" justifyContent="left" sx={{ width: "100%" }}>
                            <Typography variant="h6" p={2} sx={{ color: "white" }}>
                                {modalTitle}
                            </Typography>
                        </Box>

                        <Box display="flex" justifyContent="right" sx={{ pr: 2, width: "100%" }}>
                            <CloseIcon
                                onClick={handleModalClose}
                                sx={{
                                    color: "white",
                                    "&:hover": {
                                        color: "#9d9d9d",
                                    },
                                }}
                                fontSize="large"
                                color="action"
                            />
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            padding: 2,
                            width: '100%',
                            backgroundColor: "white"
                        }}
                    >
                        {children}
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: "100%",
                            height: "12%",
                            backgroundColor: "rgba(52,58,64,1)",
                            touchAction: 'none',
                        }}
                        {...bind()}
                    >
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            sx={{
                                padding: "5px 5 5px",
                                marginBottom: 3,
                                marginTop: 3,
                                marginLeft: 1,
                                "&:disabled": {
                                    color: "white",
                                },
                            }}
                        >
                            Зберегти
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default ModalMenu;
