import * as React from 'react';
import Box from '@mui/material/Box';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import NavMenuItem from "./NavMenuItem";
import LineAxisIcon from '@mui/icons-material/LineAxis';
import InventoryIcon from '@mui/icons-material/Inventory';
import InboxIcon from '@mui/icons-material/Inbox';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import WebIcon from '@mui/icons-material/Web';

interface NavigationMenuProps {
    drawerOpen: boolean;
    setOpen: (open: boolean) => void;
}

export default function NavMenu(props: NavigationMenuProps) {

    return (
        <Box sx={{display: 'flex'}}>
            <ThemeProvider
                theme={createTheme({
                    components: {
                        MuiListItemButton: {
                            defaultProps: {
                                disableTouchRipple: true,
                            },
                        },
                    },
                    palette: {
                        background: {paper: '#343a40'},
                    },
                })}
            >
                <Paper elevation={0}>
                    <Divider/>
                    <Box
                        sx={{width: '240px', pt: '10px'}}
                    >
                        <NavMenuItem subitems={getSiteLinks()}
                                     icon={<WebIcon/>}
                                     text='Сайт'
                                     setDrawerOpen={props.setOpen}
                                     drawerOpen={props.drawerOpen}/>
                        <NavMenuItem subitems={getOrdersLinks()}
                                     icon={<ShoppingCartCheckoutIcon/>}
                                     text='Замовлення'
                                     setDrawerOpen={props.setOpen}
                                     drawerOpen={props.drawerOpen}/>
                        <NavMenuItem subitems={getMarketingsLinks()}
                                     icon={<LineAxisIcon/>}
                                     text='Маркейтинг'
                                     setDrawerOpen={props.setOpen}
                                     drawerOpen={props.drawerOpen}/>
                        <NavMenuItem subitems={getSkladLinks()}
                                     icon={<InventoryIcon/>}
                                     text='Склад'
                                     setDrawerOpen={props.setOpen}
                                     drawerOpen={props.drawerOpen}/>
                        <NavMenuItem subitems={getProductsLinks()}
                                     icon={<InboxIcon/>}
                                     text='Товари'
                                     setDrawerOpen={props.setOpen}
                                     drawerOpen={props.drawerOpen}/>
                        <NavMenuItem subitems={getStatisticsLinks()}
                                     icon={<QueryStatsIcon/>}
                                     text='Статистика'
                                     setDrawerOpen={props.setOpen}
                                     drawerOpen={props.drawerOpen}/>

                        <NavMenuItem subitems={getSettingsLinks()}
                                     icon={<SettingsIcon/>}
                                     text='Налаштування'
                                     setDrawerOpen={props.setOpen}
                                     drawerOpen={props.drawerOpen}/>
                    </Box>
                </Paper>
            </ThemeProvider>
        </Box>
    );
}

function getSiteLinks() {
    return [
        {
            name: 'Товари',
            link: '/site/products',
        }
    ]
}


function getMarketingsLinks() {
    return [
        {
            name: 'Компанії',
            link: '/marketing/сompanies',
        },
        {
            name: 'Допродажі',
            link: '/marketing/additional-sales',
        }
    ]
}

function getSkladLinks() {
    return [
        {
            name: 'Постачальники',
            link: '/storage/suppliers',
        },
        {
            name: 'Прихід',
            link: '/storage/coming',
        },
        {
            name: 'Списання',
            link: '/storage/write-off',
        },
        {
            name: 'Рух',
            link: '/storage/move',
        }
    ]
}

function getProductsLinks() {
    return [
        {
            name: 'Перелік',
            link: '/products',
        },
        {
            name: 'Категорії',
            link: '/products/categories',
        },
        {
            name: 'Виробники',
            link: '/products/manufacturers',
        },
        {
            name: 'Атрибути',
            link: '/products/attributes',
        }
    ]
}

function getSettingsLinks() {
    return [
        {
            name: 'Основні',
            link: '/settings/basics',
        },
        {
            name: 'Користувачі',
            link: '/settings/users',
        },
        {
            name: 'Группи',
            link: '/settings/groups',
        },
        {
            name: 'Статуси',
            link: '/settings/statuses',
        },
        {
            name: 'Відділи',
            link: '/settings/offices',
        },
        {
            name: 'Безпека',
            link: '/settings/security',
        },
        {
            name: 'Модулі',
            link: '/settings/modules',
        }
    ]
}

function getOrdersLinks() {
    return [
        {
            name: 'Перелік',
            link: '/orders',
        },
        {
            name: 'Кошик',
            link: '/orders/deleted',
        }
    ]
}

function getStatisticsLinks() {
    return [
        {
            name: 'Основна',
            link: '/statistics',
        },
        {
            name: 'Дзвінки',
            link: '/statistics/phones',
        }
    ]
}