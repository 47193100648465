import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

const Console = () => {

    return (
        <Paper
            sx={{
                bgcolor: "#1C1C1E",
                borderRadius: "4px",
                p: (theme) => theme.spacing(2),
                overflow: "auto",
                maxHeight: "300px",
                fontFamily: "Menlo, Consolas, monospace",
                color: "#fff",
                width:"400px",
                height: "200px",
                mb: 3
            }}
            elevation={3}
        >
            <Box sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: (theme) => theme.spacing(1),
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                            mr: (theme) => theme.spacing(1),
                            bgcolor: "#FFCC00",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: (theme) => theme.spacing(1),
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                            mr: (theme) => theme.spacing(1),
                            bgcolor: "#29D398",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: (theme) => theme.spacing(1),
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                            mr: (theme) => theme.spacing(1),
                            bgcolor: "#f6415e",
                        }}
                    />
                </Box>

            </Box>
            <Box
                component="div"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mt:1,
                    "&::before": {
                        content: "'$ apt-get install ADS-HERO -y'",
                        mr: (theme) => theme.spacing(1),
                    },
                }}
            />
            <Box
                component="div"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    "&::before": {
                        content: "'................................'",
                        mr: (theme) => theme.spacing(1),
                    },
                }}
            />
            <Box
                component="div"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    "&::before": {
                        content: "'................................'",
                        mr: (theme) => theme.spacing(1),
                    },
                }}
            />
            <Box
                component="div"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    "&::before": {
                        content: "'................................'",
                        mr: (theme) => theme.spacing(1),
                    },
                }}
            />
            <Box
                component="div"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                    "&::before": {
                        mr: (theme) => theme.spacing(1),
                    },
                }}
            />

            <Box
                component="div"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    "&::before": {
                        content: "'=> Please login first'",
                        mr: (theme) => theme.spacing(1),
                    },
                }}
            />
        </Paper>
    );
};

export default Console;