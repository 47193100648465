import {  ReactNode, createElement } from 'react';
import { IconContext } from 'react-icons';
import {IconType} from "react-icons/lib";

export function createIcon(icon: IconType | ReactNode): ReactNode {
    if (typeof icon === 'string' || typeof icon === 'number') {
        return icon;
    }

    if (typeof icon === 'function') {
        return createElement(icon)
    }

    return icon || null;
}
