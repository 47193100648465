import {GridSortDirection} from "@mui/x-data-grid/models/gridSortModel";
import {IconType} from "react-icons/lib";
import {createElement, ReactNode} from "react";
import { IconContext } from "react-icons";
import store from "../redux/store";
import {setNotifyOpen, setNotifyText, setNotifyType} from "../redux/slices/notifySlice";


export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getItems = (count: number) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `Item ${k + 1}`,
        primary: `Item ${k + 1}`,
        secondary: `Item ${k + 1}`
    }));


export function getNotifyType(isSuccess: boolean) {
    return isSuccess ? 'success' : 'error';
}

export function getNotifyText(isSuccess: boolean) {
    return isSuccess ? 'Збережено' : 'Виникла Помилка. Зверніться до Адміністратора.';
}

export function sortByFieldAndOrder<T>(data: T[], field: keyof T, sort: GridSortDirection): T[] {
    const sortedData = [...data];
    return sortedData.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (sort === 'asc') {
            if (valueA < valueB) return -1;
            if (valueA > valueB) return 1;
        } else if (sort === 'desc') {
            if (valueA > valueB) return -1;
            if (valueA < valueB) return 1;
        }

        return 0;
    });
}

export function sendError(message:any){
    store.dispatch(setNotifyText(message));
    store.dispatch(setNotifyType('error'));
    store.dispatch(setNotifyOpen(true));
}








