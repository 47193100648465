import React, { useState, useRef } from 'react';
import { Card, CardMedia, Typography, IconButton } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import CloseIcon from '@mui/icons-material/Close';

const PhotoZone: React.FC = () => {
    const [photoUrl, setPhotoUrl] = useState<string>('');
    const [isHovered, setIsHovered] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        uploadPhoto(event.dataTransfer.files[0]);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            uploadPhoto(file);
        }
    };

    const uploadPhoto = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const uploadedPhotoUrl = e.target?.result as string;
            setPhotoUrl(uploadedPhotoUrl);
        };
        reader.readAsDataURL(file);
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event propagation
        setPhotoUrl('');
    };

    return (
        <Card
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
            onClick={handleClick}
            style={{
                backgroundColor: 'white',
                border: '1px dashed #ccc',
            }}
        >
            <CardMedia
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                    position: 'relative',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {photoUrl ? (
                    <>
                        <img
                            src={photoUrl}
                            alt="Uploaded photo"
                            style={{ objectFit: 'fill', width: '100%', maxHeight: '100%' }}
                        />
                        {isHovered && (
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    top: '4px',
                                    right: '4px',
                                    color: 'white',
                                }}
                                onClick={handleDelete}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </>
                ) : (
                    <>
                        <PhotoIcon style={{ fontSize: '100px', color: '#ccc' }} />
                        <Typography sx={{ color: '#757575' }} variant="subtitle1">
                            Завантажити зображення
                        </Typography>
                    </>
                )}
            </CardMedia>
            <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
            />
        </Card>
    );
};

export default PhotoZone;
