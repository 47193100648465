import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    notifyText: 'Привіт',
    notifyType: 'success',
    notifyOpen: false
}

export const notifysSlice = createSlice({
    name: 'notify',
    initialState,
    reducers: {
        setNotifyText: (state, action) => {
            state.notifyText = action.payload
        },
        setNotifyType: (state, action) => {
            state.notifyType = action.payload
        },
        setNotifyOpen: (state, action) => {
            state.notifyOpen = action.payload
        }
    }
})

export const {
    setNotifyText,
    setNotifyType,
    setNotifyOpen
} = notifysSlice.actions

export default notifysSlice.reducer