import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import errorImage from '../assets/images/herocat.webp';

const Error404Page = () => {

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Typography  sx={{ mb:3, fontFamily: 'Bree Serif', fontWeight: '600'}} variant="h2" component="h1">
                    Доступ заборонено
                </Typography>
                <img src={errorImage} alt="Error" style={{ maxWidth: '100%', maxHeight: '100%',marginBottom:'10rem'}} />
            </Box>
        </Box>
    );
};

export default Error404Page;