import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    action: '',
}

export const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        setCurrentAction: (state, action) => {
            state.action = action.payload
        }
    }
})

export const {
    setCurrentAction,
} = actionsSlice.actions

export default actionsSlice.reducer