import React from 'react';
import './App.css';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNotifyOpen } from './redux/slices/notifySlice';
import { useAuth } from "react-oidc-context";
import Spinner from "./components/annimated/Spinner";
import MainRouter from "./services/MainRouter";
import LoginPage from "./pages/login/LoginPage";

function LoadingComponent() {
    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Spinner />
        </div>
    );
}

function App() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const notifyText = useSelector((state: any) => state.notifys.notifyText);
    const notifyType = useSelector((state: any) => state.notifys.notifyType);
    const notifyOpen = useSelector((state: any) => state.notifys.notifyOpen);

    const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        dispatch(setNotifyOpen(false));
    };

    if (auth.activeNavigator === "signinSilent" || auth.activeNavigator === "signoutRedirect" || auth.isLoading || auth.error) {
        return <LoadingComponent />;
    }

    if (auth.isAuthenticated) {
        return (
            <div className="wrapper">
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Snackbar
                            autoHideDuration={6000}
                            onClose={handleCloseNotification}
                            open={notifyOpen}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Alert severity={notifyType} sx={{ width: '100%' }} onClose={handleCloseNotification}>
                                {notifyText}
                            </Alert>
                        </Snackbar>
                        <MainRouter />
                    </Box>
                </Box>
            </div>
        );
    }

    return <LoginPage />;
}

export default App;