import { GridCellParams } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import { useState } from 'react';
import {setProductEnabled} from "../../apis/sklad/SkladAPI";

interface SwitchCellProps{
    productId: any,
    params: GridCellParams,
}

export default function SwitchCell (data: SwitchCellProps){
    const [value, setValue] = useState(data.params.value as boolean);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;

        setProductEnabled(data.productId, newValue)
            .then(response => {
                if(response.data?.success){
                    setValue(newValue);
                }
            });
    };

    return <Switch checked={value} onChange={handleChange} />;
};