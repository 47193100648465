import React from "react";
import {Route, Routes} from "react-router";
import Dashboard from "../pages/dashboard/Dashboard";
import Navigation from "../components/navigation/Navigation";
import {styled} from "@mui/material/styles";
import SiteProducts from "../pages/site/SiteProducts";
import Error404Page from "../pages/Error404Page";
import Products from "../pages/products/Products";

export default function MainRouter() {

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    }));

    return (
        <div>
            <DrawerHeader/>
            <Navigation/>
            <Routes>
                <Route path="/" element={<Dashboard/>}/>
                {/*<Route path="/marketing/сompanies" element={<Companies />} />*/}
                {/*<Route path="/marketing/additional-sales" element={<AdditionalSales/>} />*/}
                <Route path="/products" element={<Products/>}/>
                <Route path="/site/products" element={<SiteProducts/>}/>
                <Route path="*" element={<Error404Page/>}/>
            </Routes>
        </div>)
}

