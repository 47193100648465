import React, {FC, ReactNode, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {IconType} from "react-icons/lib";
import {createIcon} from "../../../utils/iconHelper";

interface CrmTextSelectProps {
    label: string;
    icon: ReactNode | IconType;
    labelStyle?: any;
    onChange?: (value: any) => void;
    data:any;
    width?: string;
    defaultValue?: {label: string, id: any} | null | undefined
}

const CargoDescriptionSelect: FC<CrmTextSelectProps> = ({label, icon, labelStyle, data, width, defaultValue, onChange}: CrmTextSelectProps) => {

    const [transformedData, setTransformedData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOpen = (event: React.SyntheticEvent) => {
        event.preventDefault()
        loadData('')
    };

    const loadData = (param:string) => {
        setLoading(true);
        data(param)
            .then((response: any) => {
                if (response.status === 200) {
                    if(response.data.error) setLoading(true)
                    const transformedData = response.data.map((data: any) => {
                        return {
                            label: data.label,
                            id: data.id
                        };
                    });
                    setTransformedData(transformedData);
                    setLoading(false);
                }
            })
    };

    const handleChange = (param:any) => {
        loadData(param)
    };


    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <label htmlFor="standard-basic" style={{ marginRight: '5px', marginTop: '15px', padding: 0, color: '#757575' }}>
                <div style={labelStyle}>{label}</div>
            </label>
            <div style={{ marginTop: '15px',marginRight: '5px', color: '#A9CEE7' }}>{createIcon(icon)}</div>
            <Autocomplete
                value={defaultValue}
                loading={loading}
                options={transformedData}
                onOpen={handleOpen}
                onInputChange={(event, value) => handleChange(value)}
                onChange={(event, value) => {onChange && onChange(value)}}
                sx={{width: width? width: '100%'}}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField{...params}
                                                   id="standard-basic"
                                                   variant="standard"
                                                   sx={{
                                                       padding: '4px 0px 0px',
                                                       '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                           borderBottom: '1px dotted',
                                                       },
                                                       '& .MuiInput-underline:after': {
                                                           borderBottom: '1px dotted',
                                                       },
                                                   }}/>}
            />
        </div>
    );
};

export default CargoDescriptionSelect;