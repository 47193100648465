import React from 'react';
import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";
import { useAuth } from "react-oidc-context";

const GlowingButton = styled(Button)(({ theme }) => ({
    padding: '0.6em 2em',
    border: 'none',
    outline: 'none',
    color: theme.palette.common.white,
    background: '#111',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 0,
    borderRadius: '10px',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    touchAction: 'manipulation',
    fontWeight: '600',
    '&:before': {
        content: '""',
        background: 'linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000)',
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        backgroundSize: '400%',
        zIndex: -1,
        filter: 'blur(5px)',
        WebkitFilter: 'blur(5px)',
        width: 'calc(100% + 4px)',
        height: 'calc(100% + 4px)',
        animation: 'glowing-button 20s linear infinite',
        transition: 'opacity 0.3s ease-in-out',
        borderRadius: '10px',
    },
    '&:hover:before': {
        opacity: 0.3,
    },
    '&:after': {
        zIndex: -1,
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '#222',
        left: 0,
        top: 0,
        borderRadius: '10px',
    },
}));

const LoginButton = () => {
    const auth = useAuth();
    return (<GlowingButton onClick={() => void auth.signinRedirect()} variant="contained"> АВТОРИЗАЦІЯ </GlowingButton>);
};

export default LoginButton;