import * as React from 'react';
import {styled} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSelector, useDispatch } from 'react-redux'
import {setCurrentTab} from "../../redux/slices/tabsSlice";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        fontWeight: 'bolder',
        backgroundColor: '#ffffff',
    },
});

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({theme}) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(1),
    color: 'rgba(152,152,152,0.7)',
    '&.Mui-selected': {
        color: '#fff',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));

interface Tab {
    [path: string]: string[];
}

export default function HeadTabs() {
    const [value, setValue] = React.useState(0);

    const dispatch = useDispatch()

    let currentPath = window.location.pathname

    const tabs: Tab[] = [
        {"/products": ["УСІ СКЛАДИ", "СКЛАД 1", "СКЛАД 2"]}
    ];

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        dispatch(setCurrentTab(newValue))
    };

    const currentTabLabels = tabs.find((tab) => tab[currentPath]);

    return (
        <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="Меню вибору"
        >
            {currentTabLabels &&
                currentTabLabels[currentPath].map((label, index) => (
                    <StyledTab key={index} label={label}/>
                ))}
        </StyledTabs>
    );
}