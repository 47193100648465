import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDispatch} from "react-redux";
import {setCurrentAction} from "../redux/slices/actionsSlice";

interface MenuActionsProps{
}

export default function ButtonMenuActions(props: MenuActionsProps) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (action: any) => {
        dispatch(setCurrentAction(action))
    };

    let currentPath = window.location.pathname

    const filteredActions = actions.filter((action) => action.path === currentPath);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {filteredActions.length !==0 &&
                <Tooltip title="Меню дій">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <SettingsIcon sx={{color: 'white' ,"&:hover": { color: "rgb(49,64,93)" } }} fontSize="large" />
                    </IconButton>
                </Tooltip>}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="products-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {filteredActions.map((action) => (
                    <MenuItem key={action.label} onClick={()=>handleOpenMenu(action.label)}>
                        <ListItemIcon>{action.icon}</ListItemIcon>
                        {action.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}

const actions = [
    {
        path: '/products',
        label: 'Додати товар',
        icon: <AddCircleOutlineIcon fontSize="small" />
    },
    {
        path: '/products',
        label: 'Видалити товар',
        icon: <DeleteIcon fontSize="small" />
    }
];