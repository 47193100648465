import React, {ChangeEvent, useState} from "react";
import Box from '@mui/material/Box';
import {Alert, Button, FormControlLabel, FormGroup, Modal, Snackbar, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {createProduct} from "../apis/site/SiteAPI";
import {AxiosResponse} from "axios";
import { SiteProductMenuProps } from "../utils/SiteProductMenuProps";

function checkIsCommentsValid(text: string): boolean {
    const commentsArr = text.split('\n');
    for (const comment of commentsArr) {
        const splitComment = comment.split(': ');
        if (splitComment.length !== 2 || splitComment[0].trim().length === 0 || splitComment[1].trim().length === 0) {
            return false;
        }
    }
    return true;
}

function getFormatedTheses(theses: string[]): string {
    let result: string = '';
    theses.forEach(text=>{
        result+=text + '\n'
    })
    return result
}

function getFormatedComments(comments: any[]): string {
    return comments.reduce((result, comment, index) => {
        const formattedComment = `${comment.userName}: ${comment.text}`;
        return result + formattedComment + (index !== comments.length - 1 ? '\n' : '');
    }, '');
}

export default function SiteProductMenu(props: SiteProductMenuProps) {

    const [id, setId] = useState(props.isNewProduct? 0 : props.product.id);
    const [name, setName] = useState(props.isNewProduct? '' : props.product.name);
    const [productId, setProductId] = useState(props.isNewProduct? '' : props.product.productId);
    const [category, setCategory] = useState(props.isNewProduct? '' : props.product.category);
    const [price, setPrice] = useState(props.isNewProduct? '' : props.product.price);
    const [pixel, setPixel] = useState(props.isNewProduct? '' : props.product.pixelID);
    const [video, setVideoID] = useState(props.isNewProduct? '' : props.product.videoId);
    const [description, setDescription] = useState(props.isNewProduct? '' : props.product.description);
    const [commentCRM, setCommentCRM] = useState(props.isNewProduct? '' : props.product.orderComment);

    const [theses, setTheses] = useState<string[]>(props.isNewProduct? [] : props.product.theses);
    const [comments, setComments] = useState<object[]>(props.isNewProduct? [] : props.product.comments);

    const [hasSale, setHasSale] = useState(props.isNewProduct? true : props.product.hasSale);
    const [isMainPage, setIsMainPage] = useState(props.isNewProduct? false : props.product.main);
    const [isActive, setIsActive] = useState(props.isNewProduct? true : props.product.enabled);

    const [isCommentsValid, setIsCommentsValid] = useState(checkIsCommentsValid(getFormatedComments(comments)));
    const [isUploaded, setIsUploaded] = useState(!props.isNewProduct);

    const [uploadedFiles, setUploadedFiles] = useState<object[]>(props.isNewProduct? [] : props.product.images);

    const [openNotification, setOpenNotification] = React.useState(false);

    let payload = {
        "id": id,
        "name": name,
        "productId": productId,
        "price": price,
        "pixelID": pixel,
        "theses": theses,
        "orderComment": commentCRM,
        "comments": comments,
        "description": description,
        "images": uploadedFiles,
        "characteristicType": "Тип",
        "videoId": video,
        "category": category,
        "main": isMainPage,
        "hasSale": hasSale,
        "enabled": isActive
    }

    const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };


    const handleMenuClose = () => {
        props.setIsProductMenuOpen(false);
    }

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) {
            return;
        }
        const url = "https://yellowshop.in.ua/fileupload.php";

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files[]", files[i]);
        }

        fetch(url, {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setIsUploaded(true);
                const uploadedFiles = [];
                for (let i = 0; i < files.length; i++) {
                    const fileName = files[i].name;
                    const fileExtension = 'webp';
                    const fileUrl = `https://yellowshop.in.ua/images/products/${fileName.replace(/\.[^/.]+$/, "")}.${fileExtension}`;
                    uploadedFiles.push({
                        "priority": i + 1,
                        "link": fileUrl
                    });
                }
                setUploadedFiles(uploadedFiles);
                return response.text();
            })
            .then((data) => {
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleChangeProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleChangeProductID = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProductId(event.target.value);
    }

    const handleChangeProductCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategory(event.target.value);
    }

    const handleChangeProductPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(event.target.value);
    }

    const handleChangeProductPixel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPixel(event.target.value);
    }

    const handleChangeProductVideoID = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVideoID(event.target.value);
    }

    const handleChangeHasSale = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasSale(event.target.checked);
    };

    const handleChangeMain = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMainPage(event.target.checked);
    };

    const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const handleChangeCommentCRM = (event: React.ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value
        if (text.includes('\n')) text = text.replace('\n', ' ')
        setCommentCRM(text)
    };

    const handleChangeTheses = (event: React.ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value;
        let theses: string[] = [];

        if (text.includes('\n')) {
            theses = text.split('\n');
        } else {
            theses.push(event.target.value);
        }

        setTheses(theses);
    };

    const handleChangeComments = (event: React.ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value;

        let commentsArr: object[] = [];

        let isValid: boolean = checkIsCommentsValid(text);
        setIsCommentsValid(isValid);

        if (isValid) {
            if(text.includes('\n')) {
                let splitedComments: string[] = text.split('\n')
                splitedComments.forEach((line:string) => {
                    let splitComment: string[] = line.split(': ')
                    let commentObj = {
                        "userName": splitComment[0],
                        "text": splitComment[1]
                    }
                    commentsArr.push(commentObj)
                })
            }else {
                let splitComment = text.split(': ')
                let commentObj = {
                    "userName": splitComment[0],
                    "text": splitComment[1]
                }
                commentsArr.push(commentObj)
            }
            setComments(commentsArr)
        }
    };

    return (
        <React.Fragment>
            <Snackbar open={openNotification} autoHideDuration={6000} onClose={handleCloseNotification}>
                <Alert  severity="success" sx={{ width: '100%' }} onClose={handleCloseNotification}>
                    This is a success message!
                </Alert>
            </Snackbar>
            <Modal
                open={props.isProductMenuOpen}
                onClose={handleMenuClose}
            >
                <Box
                    sx={{
                        mt: '10%',
                        width: '70%',
                        height: '60%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: "rgba(52,58,64,1)",
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="left"
                            sx={{width: '100%'}}
                        >
                            <Typography variant="h6" p={2} sx={{color: 'white'}}>
                                Товар
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="right"
                            sx={{pr: 2, width: '100%'}}
                        >
                            <CloseIcon
                                onClick={handleMenuClose}
                                sx={{
                                    color: 'white',
                                    "&:hover": {
                                        color: "#9d9d9d"
                                    }
                                }} fontSize="large" color="action"/>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            padding: 2,
                            width: '100%',
                            backgroundColor: "white"
                        }}
                    >

                        <Box sx={{flexGrow: 1}}>
                            <Grid container spacing={2} columns={12}>
                                <Grid item xs={3}>
                                    <Box sx={{flexGrow: 1}}>
                                        <Box display="flex" flexDirection="column" justifyContent="center">
                                            <TextField sx={{mb: 1}} id="standard-basic"
                                                       label="Назва"
                                                       variant="standard"
                                                       defaultValue={name}
                                                       onChange={handleChangeProductName}/>
                                            <TextField sx={{mb: 1}}
                                                       id="standard-basic"
                                                       type="number"
                                                       label="Id"
                                                       variant="standard"
                                                       defaultValue={productId}
                                                       onChange={handleChangeProductID}/>
                                            <TextField sx={{mb: 1}}
                                                       id="standard-basic"
                                                       label="Категорія"
                                                       variant="standard"
                                                       defaultValue={category}
                                                       onChange={handleChangeProductCategory}/>
                                            <TextField sx={{mb: 1}}
                                                       id="standard-basic"
                                                       type="number"
                                                       label="Ціна"
                                                       variant="standard"
                                                       defaultValue={price}
                                                       onChange={handleChangeProductPrice}/>
                                            <TextField sx={{mb: 1}}
                                                       id="standard-basic"
                                                       label="Піксель"
                                                       variant="standard"
                                                       defaultValue={pixel}
                                                       onChange={handleChangeProductPixel}/>
                                            <TextField sx={{mb: 1}}
                                                       id="standard-basic"
                                                       label="Відео Id"
                                                       variant="standard"
                                                       defaultValue={video}
                                                       onChange={handleChangeProductVideoID}/>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={hasSale} onChange={handleChangeHasSale}/>}
                                                                  label="Має знижку"/>
                                                <FormControlLabel control={<Checkbox checked={isMainPage} onChange={handleChangeMain}/>}
                                                                  label="На головній"/>
                                                <FormControlLabel control={<Checkbox checked={isActive} onChange={handleChangeActive}/>}
                                                                  label="Активний"/>
                                            </FormGroup>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Тезиси"
                                        multiline
                                        rows={8}
                                        fullWidth
                                        defaultValue={getFormatedTheses(theses)}
                                        onChange={handleChangeTheses}
                                        error={theses.length === 0}
                                    />
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Опис товару на сайті"
                                        multiline
                                        rows={8}
                                        fullWidth
                                        defaultValue={description}
                                        sx={{mt: 2}}
                                        onChange={handleChangeDescription}
                                        error={!description}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Коментарі"
                                        multiline
                                        rows={8}
                                        fullWidth
                                        defaultValue={getFormatedComments(comments)}
                                        onChange={handleChangeComments}
                                        error={!isCommentsValid}
                                    />
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Коментар до товару у CRM"
                                        multiline
                                        rows={5}
                                        fullWidth
                                        defaultValue={commentCRM}
                                        sx={{mt: 2}}
                                        onChange={handleChangeCommentCRM}
                                    />
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Button
                                            key={new Date().getTime()}
                                            component="label"
                                            variant="outlined"
                                            startIcon={isUploaded ? null : <UploadFileIcon/>}
                                            endIcon={isUploaded ? <CheckCircleOutlineIcon/> : null}
                                            sx={{mt: 2, marginRight: "1rem", bgcolor: isUploaded ? "rgba(104,248,44,0.2)" : "inherit"}}
                                        >
                                            {isUploaded ? "Завантажено": "Завантажити зображення"}
                                            <input type="file" multiple accept=".webp,.jpg,.png,.jpeg" hidden onChange={handleFileUpload} />
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            height: '12%',
                            backgroundColor: "rgba(52,58,64,1)"
                        }}
                    >
                        <Button variant="contained"
                                disabled={
                                    !name ||
                                    !productId ||
                                    !category ||
                                    !price ||
                                    !pixel ||
                                    !description ||
                                    !isCommentsValid ||
                                    !isUploaded
                                }
                                onClick={() => {
                                    createProduct(payload).then((response: AxiosResponse) => {
                                        let data = response.data
                                        if (data.success) {
                                            props.setIsSuccess(true);
                                            props.setIsProductMenuOpen(false);
                                        } else {
                                            console.error('Failed to create product:', data.message);
                                            props.setIsSuccess(false);
                                        }
                                        props.setOpenNotification(true)
                                    });
                                }}
                                sx={{
                                    padding: '5px 5 5px',
                                    marginBottom: 3,
                                    marginTop: 3,
                                    marginLeft: 1,
                                    "&:disabled": {
                                        color: 'white'
                                    }
                                }}
                        > Зберегти
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    );
}