import {
    GridCellParams,
    GridColDef,
    GridFilterModel,
    GridRowParams,
    GridRowSelectionModel,
    ukUA
} from "@mui/x-data-grid";

import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import StyledDataGrid from "../datagrid/StyledDataGrid";
import { AiOutlineBarcode, AiFillPrinter } from "react-icons/ai";

export default function ProductModelsMenu() {

    const rows = [
        {"id": 1 , "name": "Test1", "quantity": 1},
        {"id": 2 , "name": "Test2", "quantity": 1},
        {"id": 3 , "name": "Test3", "quantity": 1},
        {"id": 4 , "name": "Test4", "quantity": 1},
        {"id": 5 , "name": "Test5", "quantity": 1},
        {"id": 6 , "name": "Test6", "quantity": 1},
        {"id": 7 , "name": "Test7", "quantity": 1},
        {"id": 8 , "name": "Test8", "quantity": 1},
        {"id": 9 , "name": "Test9", "quantity": 1},
        {"id": 10 , "name": "Test10", "quantity": 1},
    ]

    //const [rows, setRows] = useState([]);

    const [rowCountState, setRowCountState] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        page: 0,
        pageSize: 10,
        totalRowCount: 0,
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const handleIconClick = (id: number) => {
        console.log(`Icon clicked for id: ${id}`);
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Sub ID',
            description: 'Sub ID Товару',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            maxWidth: 100,
            sortable: false,
        },
        {
            field: 'name',
            headerName: 'Назва моделі',
            description: 'Назва товару',
            align: 'left',
            headerAlign: 'center',
            flex: 2,
            sortable: false,
        },
        {
            field: 'quantity',
            headerName: 'Кількість',
            description: 'Кількість товару на складі',
            align: 'center',
            headerAlign: 'center',
            maxWidth: 100,
            flex: 3,
            sortable: false,
        },
        {
            field: 'barcode',
            headerName: 'Друк штрих коду',
            description: 'Друк штрих коду моделі товару',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            maxWidth: 50,
            sortable: false,
            renderHeader: () => (
                <AiOutlineBarcode/>
            ),
            renderCell: (params: GridCellParams) => (
                <div className="print-icon-container">
                    <AiFillPrinter className="print-icon" onClick={() => handleIconClick(params.row.id as number)} />
                </div>
            ),
        },
    ];

    return (
        <Box>
                <StyledDataGrid
                    disableColumnMenu
                    rowHeight={20}
                    headerHeight={10}
                    rows={rows}
                    rowCount={rowCountState}
                    columns={columns}
                    pageSizeOptions={[10]}
                    localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
                    //onRowDoubleClick={handleOpenProductMenu}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    // onPaginationModelChange={setPaginationModel}
                    // onFilterModelChange={onFilterChange}
                    // onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                    //     setRowSelectionModel(newRowSelectionModel);
                    // }}
                    // rowSelectionModel={rowSelectionModel}
                    sx={{'& .MuiDataGrid-columnHeaders': {
                            height: `30px`,
                            minHeight: '0px!important',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            height: `30px`,
                            minHeight: '0px!important',
                        },}}

                />
        </Box>
    )
}




