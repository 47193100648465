import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    tabId: 0,
}

export const tabsSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setCurrentTab: (state, action) => {
            state.tabId = action.payload
        }
    }
})

export const {
    setCurrentTab,
} = tabsSlice.actions

export default tabsSlice.reducer