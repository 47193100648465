import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import {AuthProvider, AuthProviderProps} from "react-oidc-context";

import store, {persistor} from '../src/redux/store'
import { config } from './utils/constants'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const oidcConfig: AuthProviderProps = {
    authority: config.auth.URL,
    client_id: config.auth.CLIENT,
    redirect_uri: config.auth.REDIRECT_URI,
    onSigninCallback: (): void => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
};

root.render(
    <AuthProvider {...oidcConfig}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </AuthProvider>
);

reportWebVitals();
