import axios from 'axios'

import { config } from '../../utils/constants'
import {sendError} from "../../utils/helpers";

const API_URL = config.api.FAST_BASE_URL;

const api = axios.create({
    baseURL: API_URL,
});

export async function getCargoDescription(findText: string) {
    try {
        const encodedFindText = encodeURIComponent(findText);
        const param = encodedFindText ? `?find=${encodedFindText}` : '';

        return await api.get(`/getCargoDescription${param}`);
    } catch (error: any) {
        let message = 'Невідома помилка';
        sendError(message);
        throw error;
    }
}
