import TextField, {TextFieldProps} from '@mui/material/TextField';
import { FC, ReactNode} from 'react';
import {IconType} from "react-icons/lib";
import {createIcon} from "../../../utils/iconHelper";

interface CrmNumericFieldProps extends Omit<TextFieldProps, 'size' | 'id' | 'variant'> {
    label: string;
    icon: ReactNode | IconType;
    labelStyle?: any;
    postfix?: string;
}

const CrmNumericField: FC<CrmNumericFieldProps> = ({ labelStyle ,label, icon, postfix, ...textFieldProps}) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <label htmlFor="standard-basic" style={{ marginRight: '5px', marginTop: '15px', padding: 0, color: '#757575' }}>
                <div style={labelStyle}>{label}</div>
            </label>
            <div style={{ marginTop: '15px',marginRight: '5px', color: '#A9CEE7' }}>{createIcon(icon)}</div>
            <TextField
                size="small"
                id="standard-basic"
                variant="standard"
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9.]*',
                }}
                sx={{
                    width: '100%',
                    padding: '4px 0px 0px',
                    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottom: '1px dotted',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottom: '1px dotted',
                    },
                    '& .MuiInput-input':{
                        padding: '0px',
                    }
                }}
                {...textFieldProps}
            />
            <div style={{color: '#757575', fontWeight: "bold", marginLeft: '5px'}}>{postfix}</div>
        </div>
    );
};

export default CrmNumericField;