import React, {useState, useEffect, ChangeEvent} from "react";
import Box from '@mui/material/Box';
import {TextField} from "@mui/material";
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LanguageIcon from '@mui/icons-material/Language';
import Stack from '@mui/material/Stack';

import ModalMenu from "./ModalMenu";
import CrmTextField from "./components/CrmTextField";
import CrmPropSelect from "./components/CrmPropSelect";
import {getStoragesList, getManufacturersList, getVendorsList, getCategoriesList} from "../../apis/sklad/SkladAPI";
import {getCargoDescription} from "../../apis/novaposhta/NovaPoshtaAPI";
import {TbBuildingWarehouse, TbStatusChange, TbListNumbers,TbCategory} from "react-icons/tb";
import {GiFactory, GiWeight, GiCargoShip} from "react-icons/gi";
import {RiLuggageCartFill} from "react-icons/ri";
import {AiOutlineColumnWidth,AiOutlineColumnHeight,AiOutlineBarcode,AiFillPrinter} from "react-icons/ai";
import {IoMdResize} from "react-icons/io";
import {MdAttachMoney} from "react-icons/md";
import {FaMoneyCheckAlt} from "react-icons/fa";
import PhotoZone from "./components/PhotoZone";
import CrmNumericField from "./components/CrmNumericField";
import ProductModelsMenu from "./ProductModelsMenu";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Switch from "@mui/material/Switch";
import CargoDescriptionSelect from "./components/CargoDescriptionSelect";
import { Product } from "../../utils/interfaces/product";
import {useSelector} from "react-redux";

interface ProductMenuProps {
    product: Product | null;
}

export default function ProductMenu({ product }: ProductMenuProps) {

    const action = useSelector((state:any) => state.actions.action)

    const  isProductCreate = action === 'Додати товар'

    const [isBarcodePrintIconHovered, setIsBarcodePrintHovered] = useState(false);

    const [isModelsMenuActive, setIsModelsMenuActive] = useState(false);

    const modelsMenuText = isModelsMenuActive ? "Всі моделі товару" : "Додати моделі товару";

    const [productId, setProductId] = useState(isProductCreate ? '' : product?.productId);
    const [name, setName] = useState(isProductCreate ? '' : product?.name);
    const [site, setSite] = useState(isProductCreate ? '' : product?.site);
    const [price, setPrice] = useState(isProductCreate ? '' : product?.price);
    const [priceEnter, setPriceEnter] = useState(isProductCreate ? '' : product?.priceEnter);

    const [status, setStatus] = useState(isProductCreate ? '' : product?.status || '');
    const [statusId, setStatusId] = useState(isProductCreate ? '' : product?.statusId);

    const [category, setCategory] = useState(isProductCreate ? 'Без' : product?.categoryName || '');
    const [categoryId, setCategoryId] = useState(isProductCreate ? '' : product?.categoryId);

    const [storage, setStorage] = useState(isProductCreate ? '' : product?.storageName || '');
    const [storageId, setStorageId] = useState(isProductCreate ? '' : product?.storageId);

    const [manufacturer, setManufacturer] = useState(isProductCreate ? '' : product?.manufacturerName || '');
    const [manufacturerId, setManufacturerId] = useState(isProductCreate ? '' : product?.manufacturerName);

    const [vendor, setVendor] = useState(isProductCreate ? '' : product?.vendorName || '');
    const [vendorId, setVendorId] = useState(isProductCreate ? '' : product?.vendorId);

    const [deliveryDescription, setDeliveryDescription] = useState(product?.deliveryDescription);

    const [width, setWidth] = useState(isProductCreate ? 10 : product?.width);
    const [height, setHeight] = useState(isProductCreate ? 5 : product?.height);
    const [depth, setDepth] = useState(isProductCreate ? 5 : product?.depth);
    const [weight, setWeight] = useState(isProductCreate ? 0.5 : product?.weight);

    const [image, setImage] = useState('');

    const [description, setDescription] = useState(isProductCreate ? '' : product?.description);

    const [barcode, setBarcode] = useState(isProductCreate ? '' : product?.barcode);


    const payload = {
        "productId": productId,
        "name": name,
        "categoryId": categoryId,
        "categoryName": category,
        "storageId": storageId,
        "storageName": storage,
        "manufacturerId": manufacturerId,
        "manufacturerName": manufacturer,
        "vendorId": vendorId,
        "vendorName": vendor,
        "barcode": barcode,
        "model": "Sample Model2",
        "site": site,
        "description": description,
        "deliveryDescription": deliveryDescription,
        "price": price,
        "priceEnter": priceEnter,
        "weight": weight,
        "width": width,
        "height": height,
        "depth": depth,
        "quantity": 0,
        "enabled": true,
        "status": status,
        "statusId": statusId,
        "images": [
            {
                "priority": 1,
                "url": "https://yellowshop.in.ua/images/products/1TestImageUpload-1.webp"
            }
        ]
    }

    const handleBarcodePrint = () => {
        console.log('print');
    };

    const handleNumericInputChange = (event: ChangeEvent<HTMLInputElement>, setValue: (value: number) => void) => {
        const cleanedValue = event.target.value.replace(/[^0-9.]/g, '');
        event.target.value = cleanedValue
        const [integerPart, decimalPart] = cleanedValue.split('.').map(part => part.replace(/^0+/, ''));
        const numericValue = parseFloat(`${integerPart || '0'}.${decimalPart || '0'}`);
        if (!isNaN(numericValue)) {
            setValue(numericValue);
        }
    };

    const handleChangeActiveModels = (event: ChangeEvent<HTMLInputElement>) => {
        setIsModelsMenuActive(event.target.checked);
    };

    const handleModelAdd = () =>{
        console.log(payload)
        console.log(productId)
    };

    useEffect(() => {
        if (product) {
            setProductId(product.productId);
            setName(product.name);
            setSite(product.site);
            setPrice(product.price);
            setPriceEnter(product.priceEnter);
            setStatus(product.status);
            setStatusId(product.statusId);
            setCategory(product.categoryName);
            setCategoryId(product.categoryId);
            setStorage(product.storageName);
            setStorageId(product.storageId);
            setManufacturer(product.manufacturerName);
            setManufacturerId(product.manufacturerId);
            setVendor(product.vendorName);
            setVendorId(product.vendorId);
            setDeliveryDescription(product.deliveryDescription);
            setWidth(product.width);
            setHeight(product.height);
            setDepth(product.depth);
            setWeight(product.weight);
            setDescription(product.description);
            setBarcode(product.barcode);
            console.log('fgfgfg')
        }
    }, [product]);

    return (
        <ModalMenu modalTitle={product ?'Редагування товару':'Додавання товару'}>
            <Grid container spacing={2} columns={12}>
                <Grid item xs={3}>
                    <PhotoZone/>
                    <CrmNumericField defaultValue={width}
                                     onChange={(event:any)=> handleNumericInputChange(event, setWidth)}
                                     label={'Ширина'}
                                     postfix={'см'}
                                     icon={<AiOutlineColumnWidth size="1.5em"/>}/>
                    <CrmNumericField defaultValue={height}
                                     label={'Висота'}
                                     onChange={(event:any)=> handleNumericInputChange(event, setHeight)}
                                     postfix={'см'}
                                     icon={<AiOutlineColumnHeight size="1.5em"/>}/>
                    <CrmNumericField defaultValue={depth}
                                     onChange={(event:any)=> handleNumericInputChange(event, setDepth)}
                                     label={'Глубина'}
                                     postfix={'см'}
                                     icon={<IoMdResize size="1.5em"/>}/>
                    <CrmNumericField defaultValue={weight}
                                     onChange={(event:any)=> handleNumericInputChange(event, setWeight)}
                                     label={'Вага'}
                                     postfix={'кг'}
                                     icon={<GiWeight size="1.5em"/>}/>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="row">
                        <CrmTextField defaultValue={productId}
                                      label={'ID'}
                                      icon={<TbListNumbers size="1.5em" />}
                                      onChange={(event: any)=>setProductId(event.target.value)} />
                        <div
                            style={{ marginTop: '15px', color: isBarcodePrintIconHovered ? '#82b8d9' : '#5092c2', marginLeft: '10px' }}
                            onMouseEnter={()=>setIsBarcodePrintHovered(true)}
                            onMouseLeave={()=>setIsBarcodePrintHovered(false)}
                            onClick={handleBarcodePrint}
                        >
                            <AiOutlineBarcode size="1.5em" />
                            <AiFillPrinter size="1.5em" />
                        </div>
                    </Stack>
                    <CrmTextField defaultValue={name}
                                  label={'Назва'}
                                  onChange={(event: any)=>setName(event.target.value)}
                                  icon={<BorderColorIcon/>}/>
                    <CrmTextField defaultValue={site}
                                  label={'Сайт'}
                                  onChange={(event: any)=>setSite(event.target.value)}
                                  icon={<LanguageIcon/>} />
                    <Stack direction="row">
                        <CrmNumericField defaultValue={price}
                                         label={'Ціна'}
                                         onChange={(event:any)=> handleNumericInputChange(event, setPrice)}
                                         sx={{width: '45%'}}
                                         postfix={'грн'}
                                         icon={<MdAttachMoney size="1.5em"/>}/>
                        <CrmNumericField defaultValue={priceEnter}
                                         label={'Закупка'}
                                         onChange={(event:any)=> handleNumericInputChange(event, setPriceEnter)}
                                         postfix={'грн'} sx={{width: '45%'}}
                                         icon={<FaMoneyCheckAlt size="1.5em"/>}/>
                    </Stack>
                    <Box sx={{mt:3}}>
                        <CrmPropSelect defaultValue={{label: status, id: statusId}}
                                       label={'Статус'}
                                       icon={<TbStatusChange size="1.5em"/>}
                                       onChange={(event: any)=>{setStatus(event.label); setStatusId(event.id)}}
                                       data={getCategoriesList}/>
                        <CrmPropSelect defaultValue={{label: category, id: categoryId}}
                                       label={'Категорія'}
                                       icon={<TbCategory size="1.5em"/>}
                                       onChange={(event: any)=>{setCategory(event.target.value); setCategoryId(event.id)}}
                                       data={getCategoriesList}/>
                        <CrmPropSelect defaultValue={{label: storage, id: storageId}}
                                       label={'Склад'} icon={<TbBuildingWarehouse size="1.5em"/>}
                                       onChange={(event: any)=>{setStorage(event.target.value); setStorageId(event.id)}}
                                       data={getStoragesList}/>
                        <CrmPropSelect defaultValue={{label: manufacturer, id: manufacturerId}}
                                       label={'Виробник'}
                                       icon={<GiFactory size="1.5em"/>}
                                       onChange={(event: any)=>{setManufacturer(event.target.value); setManufacturerId(event.id)}}
                                       data={getManufacturersList}/>
                        <CrmPropSelect defaultValue={{label: vendor, id: vendorId}}
                                       label={'Постачальник'}
                                       icon={<GiCargoShip size="1.5em"/>}
                                       onChange={(event: any)=>{setVendor(event.target.value); setVendorId(event.id)}}
                                       data={getVendorsList}/>
                        <CargoDescriptionSelect
                                       label={'Опис вантажу'}
                                       width={'67%'}
                                       icon={<RiLuggageCartFill size="1.5em"/>}
                                       onChange={(event: any)=>setDeliveryDescription(event.target.value)}
                                       data={getCargoDescription}/>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Опис товару"
                        multiline
                        rows={5}
                        fullWidth
                        sx={{mt:2, mb:3}}
                        onChange={(event: any)=>setDescription(event.target.value)}
                    />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <div style={{ color: '#757575', fontWeight: 'bold'}}>{modelsMenuText}</div>
                        <Switch checked={isModelsMenuActive} onChange={handleChangeActiveModels}/>
                        {isModelsMenuActive &&
                            <Box display="flex" justifyContent="right">
                                <PlaylistAddIcon
                                    onClick={handleModelAdd}
                                    sx={{
                                        color: "#5092c2",
                                        "&:hover": {
                                            color: "#82b8d9",
                                        },
                                    }}
                                    fontSize="medium"
                                />
                            </Box>
                        }
                    </Box>
                    {isModelsMenuActive && <ProductModelsMenu/>}
                </Grid>
            </Grid>
        </ModalMenu>
    );
}
//labelStyle={{marginLeft: '10px'}}