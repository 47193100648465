import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import heroBanner from "../../assets/images/herobanner.webp";
import LoginButton from "../../components/LoginButton";
import Console from "../../components/Console";

const LoginPage = () => {

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                minHeight: '90vh'
            }}
        >
            <Box
                component="img"
                alt="Hero banner"
                src={heroBanner}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',

                }}
            >

                <Box sx={{ backgroundImage: "linear-gradient(to right, yellow, orange)", mb: 0.5, borderRadius: '10px' }}>
                    <Typography
                        sx={{ pl:2,fontFamily: 'Bree Serif', fontWeight: '600'}}
                        variant="h4"
                        component="h1"
                    >
                        ADS HERO 2.0 🔨
                    </Typography>

                </Box>
                <Typography sx={{mb: 2, fontFamily: 'Bree Serif',fontWeight: '600'}} variant="h4" component="h1">
                    Необхідна Авторизація
                </Typography>
                <Console/>
                <LoginButton/>
            </Box>
        </Box>
    );
};

export default LoginPage;