import axios from 'axios'

import { config } from '../../utils/constants'
import {sendError} from "../../utils/helpers";
import {getAuthHeader} from "../authHeader";

const API_URL = config.api.SKLAD_BASE_URL;

const api = axios.create({
    baseURL: API_URL,
});

export function getPaginatedProducts(page:number,pageSize:number) {
    console.log(getAuthHeader())

    return api.get(`/products/?page=${page}&size=${pageSize}`);
}

export async function getPaginatedProductsByStorage(storageId: number,page:number,pageSize:number) {
    try {
        return await api.get(`/products/storages/${storageId}?page=${page}&size=${pageSize}`);
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}

export async function getPaginatedProductsByStorageWithFilter(data:object,storageId: number,page:number,pageSize:number) {
    try {
        return await api.post(`/products/storages/${storageId}/filtered?page=${page}&size=${pageSize}`);
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}

export async function createProduct(data:object) {
    try {
        return await api.post('/products/create');
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}

export async function getFilteredProducts(data:object,page:number,pageSize:number) {
    try {
        return await api.post(`/products/filtered?page=${page}&size=${pageSize}`);
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}

export async function setProductEnabled(productId:number, enabled:boolean) {
    try {
        return await api.post(`/products/${productId}/enabled/${enabled}`, getAuthHeader());
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}


export async function getStoragesList() {
    try {
        return await api.get(`/storages/list`, getAuthHeader());
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}

export async function getManufacturersList() {
    try {
        return await api.get(`/manufacturers/list`);
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}

export async function getVendorsList() {
    try {
        return await api.get(`/vendors/list`);
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}

export async function getCategoriesList() {
    try {
        return await api.get(`/categories/list`);
    } catch (error: any) {
        let message = 'Невідома помилка';

        if (error.response?.data) {
            if (!error.response.data.success) {
                message = error.response.data.message;
            } else {
                message = 'Неможливо отримати дані';
            }
        }
        sendError(message)
        throw error;
    }
}
