import axios from 'axios'

import { config } from '../../utils/constants'
import {getAuthHeader} from "../authHeader";

const API_URL = config.api.SITE_BASE_URL;

const api = axios.create({
    baseURL: API_URL,
});
export function getAllProducts() {
    return api.get(`/api/v1/products/all`);
}

export function getPaginatedProducts(page:number,pageSize:number) {
    return api.get(`/api/v1/products?page=${page}&size=${pageSize}`);
}

export function createProduct(data:object) {
    return api.post('/api/v1/products/add', data, {
        headers: {
           // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
}

export function getFiltredProducts(data:object,page:number,pageSize:number) {
    return api.post(`/api/v1/products/filtred?page=${page}&size=${pageSize}`, data);
}
