import * as React from "react";
import HeadTabs from "./HeadTabs";
import Box from "@mui/material/Box";

import AccountMenu from "../AccountMenu";
import ButtonMenuActions from "../ButtonMenuActions";

export default function Header() {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%'
                }}
            >
                <HeadTabs/>
            </Box>
            <ButtonMenuActions/>
            <AccountMenu/>
        </Box>
    );
}