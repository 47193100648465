import {styled} from "@mui/material/styles";
import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {darken, lighten} from "@mui/material";

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    wordWrap: 'break-word',
    "& .MuiDataGrid-virtualScrollerRenderZone": {
        "& .MuiDataGrid-row": {
            "&:nth-of-type(1n):not(.Mui-selected)": {backgroundColor: "rgba(255,255,255, .5)"},
            "&:nth-of-type(2n):not(.Mui-selected)": {backgroundColor: "rgba(224, 224, 224, .5)"},
            "&.Mui-selected": {backgroundColor: "rgba(231,212,24,0.47)"},
            '&:hover:not(.Mui-selected)': {
                backgroundColor: getHoverBackgroundColor("rgba(255,221,2,0.57)", theme.palette.mode),
            },
        },
    },
    "& .MuiDataGrid-filterIcon": {
        color: "rgba(255,255,255,1)",
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "rgba(52,58,64,1)",
        color: "rgba(255,255,255,1)",
        fontSize: 16
    },
    "& .MuiDataGrid-footerContainer": {
        backgroundColor: "rgba(52,58,64,1)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& .MuiTablePagination-selectLabel": {
            paddingTop: 0.4
        },
        "& .MuiTablePagination-displayedRows": {
            paddingTop: 0.4
        },
        "& .MuiDataGrid-selectedRowCount": {
            paddingTop: 0.4,
            color: 'white',
        },
        "& .MuiSelect-icon": {
            color: 'white'
        }
    },
    "& .MuiTablePagination-toolbar": {
        color: 'white',
    },
    "& .MuiDataGrid-cell": {
        border: 'solid 1px',
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0,
        borderColor: "white"
    },
    "& .MuiDataGrid-sortIcon": {
        opacity: 1,
        color: "white",
    },
    "& .MuiDataGrid-menuIconButton": {
        opacity: 1,
        color: "white"
    },
}));

const StyledDataGrid = (props: any) => {
    return (<CustomDataGrid {...props}/>);
};

export default StyledDataGrid;