import React, {useEffect, useState} from "react";
import SwitchCell from "../../components/datagrid/SwitchCell"
import Box from "@mui/material/Box";
import {
    GridCellParams,
    GridColDef,
    GridFilterModel, GridRowId,
    GridRowParams,  GridRowSelectionModel,
    ukUA
} from '@mui/x-data-grid';
import {Alert, Snackbar} from "@mui/material";
import {
    getFilteredProducts,
    getPaginatedProducts,
    getPaginatedProductsByStorage,
    getPaginatedProductsByStorageWithFilter
} from "../../apis/sklad/SkladAPI";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useDispatch, useSelector} from "react-redux";
import StyledDataGrid from "../../components/datagrid/StyledDataGrid";
import {getNotifyText, getNotifyType} from "../../utils/helpers";
import ProductMenu from "../../components/modals/ProductMenu";
import {setCurrentAction} from "../../redux/slices/actionsSlice";
import { Product } from "../../utils/interfaces/product";

export default function Products() {

    const dispatch = useDispatch()

    const tabId = useSelector((state:any) => state.tabs.tabId)
    const action = useSelector((state:any) => state.actions.action)

    const [isSuccess, setIsSuccess] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);

    const [rowCountState, setRowCountState] = useState(0);

    const [product, setProduct] = useState<Product | null>(null);

    const [openNotification, setOpenNotification] = useState(false);

    const [filter, setFilter] = useState<GridFilterModel|null>(null);

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const [pageInfo, setPageInfo] = useState({
        page: 0,
        pageSize: 25,
        totalRowCount: 0,
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });

    const loadData = (filterModel: GridFilterModel | null = null) => {
        setIsLoading(true);
        let productsData;
        if (filterModel) {
            productsData =
                tabId === 0
                    ? getFilteredProducts(filterModel, paginationModel.page, paginationModel.pageSize)
                    : getPaginatedProductsByStorageWithFilter(filterModel, tabId, paginationModel.page, paginationModel.pageSize);
        } else {
            productsData =
                tabId === 0
                    ? getPaginatedProducts(paginationModel.page, paginationModel.pageSize)
                    : getPaginatedProductsByStorage(tabId, paginationModel.page, paginationModel.pageSize);
        }
        productsData.then((response) => {
            let pageInfo = {
                page: response.data.number,
                pageSize: response.data.size,
                totalRowCount: response.data.totalElements,
            };
            setIsLoading(false);
            setRows(response.data.content);
            setPageInfo(pageInfo);
            setRowCountState(response.data.totalElements);
        });
    };

    useEffect(() => {
        loadData();
        console.log('Значение переменной изменилось:', tabId);
    }, [tabId]);

    useEffect(() => {
        console.log('Значение переменной изменилось:', action);
    }, [action]);


    const handleOpenProductMenu = (params: GridRowParams) => {
        setProduct(params.row);
        dispatch(setCurrentAction('Переглянути товар'))
        console.log(params.row)
    }
    const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setOpenNotification(false);
    };

   useEffect(() => {
      if(!filter) loadData();
    }, [product, paginationModel.page, paginationModel.pageSize, rowCountState, filter]);

    const onFilterChange = React.useCallback(async (filterModel: GridFilterModel) => {
        if (!filterModel.items) return;

        if(filterModel.items[0]?.value) {
            setFilter(filterModel)
            loadData(filterModel);
        }else{
            setFilter(null)
        }

    }, [paginationModel.page, paginationModel.pageSize, rowCountState]);

    return (
        <Box
            sx={{
                maxWidth: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <Snackbar
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                open={openNotification}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <Alert severity={getNotifyType(isSuccess)} sx={{width: '100%'}} onClose={handleCloseNotification}>
                    {getNotifyText(isSuccess)}
                </Alert>
            </Snackbar>

            <div style={{margin: "0 auto"}}>
                {(action === 'Додати товар'|| action === 'Переглянути товар') && <ProductMenu product={product}/>}
                <StyledDataGrid
                    rowHeight={55}
                    rows={rows}
                    rowCount={rowCountState}
                    columns={columns}
                    loading={isLoading}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
                    onRowDoubleClick={handleOpenProductMenu}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    onFilterModelChange={onFilterChange}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                />
            </div>
        </Box>
    )
}

const columns: GridColDef[] = [
    {
        field: 'productId',
        headerName: 'ID',
        description: 'ID Товару',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        minWidth: 70,
        maxWidth: 70
    },
    {
        field: 'images',
        headerName: 'Фото',
        description: 'Фото Товару',
        align: 'center',
        headerAlign: 'center',
        flex: 2,
        minWidth: 90,
        maxWidth: 90,
        valueGetter: (params) => {
            return params.value[0].url;
        },
        renderCell: (params) => <LazyLoadImage alt={'Продукт'} style={{ width: '100%', height: '100%', objectFit: 'contain'}} src={params.value} />
    },
    {
        field: 'name',
        headerName: 'Назва товару',
        description: 'Назва товару',
        minWidth: 400,
        align: 'left',
        headerAlign: 'center',
        flex: 3,
    },
    {
        field: 'price',
        headerName: 'Ціна',
        description: 'Ціна продажу товару',
        maxWidth: 90,
        minWidth: 90,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => {
            return params.value + ' грн';
        },
        flex: 4,
    },
    {
        field: 'priceEnter',
        headerName: 'Ціна закупки',
        description: 'Ціна закупки товару',
        align: 'center',
        headerAlign: 'center',
        maxWidth: 110,
        minWidth: 110,
        valueGetter: (params) => {
            return params.value + ' грн';
        },
        flex: 5,
    },
    {
        field: 'quantity',
        headerName: 'Кількість',
        description: 'Кількість товару на складі',
        align: 'center',
        headerAlign: 'center',
        maxWidth: 100,
        minWidth: 100,
        flex: 6,
    },
    {
        field: 'enabled',
        headerName: 'Викл / Вкл',
        description: 'Статус товару для продажу',
        align: 'center',
        headerAlign: 'center',
        maxWidth: 100,
        minWidth: 100,
        flex: 7,
        renderCell: (params: GridCellParams) => (
            <SwitchCell productId={params.row.productId} params={params} />
        ),
    },
    {
        field: 'categoryName',
        headerName: 'Категорія',
        description: 'Категорія товару',
        align: 'left',
        headerAlign: 'center',
        flex: 8,
    },
    {
        field: 'manufacturerName',
        headerName: 'Виробник',
        description: 'Виробник товару',
        align: 'left',
        headerAlign: 'center',
        flex: 9,
    },
    {
        field: 'status',
        headerName: 'Статус',
        description: 'Статус товару',
        align: 'center',
        headerAlign: 'center',
        flex: 9,
    },
];



