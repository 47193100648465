const prod = {
    api: {
        SITE_BASE_URL: "https://site.v2.yellowshop.in.ua",
        SKLAD_BASE_URL: "https://api.v2.yellowshop.in.ua/storage",
        FAST_BASE_URL: "http://api.v2.yellowshop.in.ua:8030/api/v2",
    },
    auth: {
        URL: "https://auth.api.v2.yellowshop.in.ua/auth/realms/master",
        CLIENT: "heroclient",
        REDIRECT_URI: "https://adshero.v2.yellowshop.in.ua/"
    }
}

const dev = {
    api: {
        SITE_BASE_URL: "https://site.v2.yellowshop.in.ua",
        SKLAD_BASE_URL: "https://api.v2.yellowshop.in.ua/storage",
        FAST_BASE_URL: "http://api.v2.yellowshop.in.ua:8030/api/v2",
    },
    auth: {
        URL: "https://auth.api.v2.yellowshop.in.ua/auth/realms/master",
        CLIENT: "heroclient",
        REDIRECT_URI: "http://localhost:3000/"
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod