import { User } from "oidc-client-ts"
import {config} from "../utils/constants";

function getUser() {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${config.auth.URL}:${config.auth.CLIENT}`)

    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export function getAuthHeader() {
    const user = getUser();
    const token = user?.access_token;
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }
}

