import React from "react";
import SiteProductsActions from "../../components/SiteProductsActions";
import SiteProductMenu from "../../components/SiteProductMenu";
import Box from "@mui/material/Box";
import {DataGrid, GridColDef, GridFilterModel, GridRowParams, ukUA} from '@mui/x-data-grid';
import {Alert, darken, lighten, Snackbar} from "@mui/material";
import {getFiltredProducts, getPaginatedProducts} from "../../apis/site/SiteAPI";

export default function SiteProducts() {

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 25,
    });

    const [isNewProduct, setIsNewProduct] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    const [pageInfo, setPageInfo] = React.useState({
        page: 0,
        pageSize: 25,
        totalRowCount: 0,
    });

    const [rowCountState, setRowCountState] = React.useState(0);

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


    const [product, setProduct] = React.useState({});
    const [isProductMenuOpen, setIsProductMenuOpen] = React.useState(false);

    const handleOpenProductMenu = (params: GridRowParams) => {
        setProduct(params.row);
        setIsProductMenuOpen(true);
        setIsNewProduct(false);
    }

    const [openNotification, setOpenNotification] = React.useState(false);
    const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };

    React.useEffect(() => {
        setIsLoading(true);
        getPaginatedProducts(paginationModel.page, paginationModel.pageSize)
            .then(response => {
                let pageinfo = {
                    page: response.data.number,
                    pageSize: response.data.size,
                    totalRowCount: response.data.totalElements,
                }
                setIsLoading(false);
                setRows(response.data.content);
                setPageInfo(pageinfo);
                setRowCountState(response.data.totalElements);
            });
    }, [isProductMenuOpen, setIsProductMenuOpen, paginationModel.page, paginationModel.pageSize, setRowCountState]);

    const columns: GridColDef[] = [
        {
            field: 'productId',
            headerName: 'ID Товару',
            description: 'ID Товару у CRM',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'name',
            headerName: 'Назва товару',
            description: 'Назва товару на сайті та у CRM',
            minWidth: 400,
            align: 'left',
            headerAlign: 'center',
            flex: 1,
        },
        {
            field: 'price',
            headerName: 'Ціна',
            description: 'Ціна продажу товару',
            maxWidth: 100,
            align: 'center',
            headerAlign: 'center',
            flex: 2,
        },
        {
            field: 'pixelID',
            headerName: 'Піксель',
            description: 'Facebook pixel для відстеження лідів',
            align: 'center',
            headerAlign: 'center',
            flex: 3,
        },
        {
            field: 'videoId',
            headerName: 'Відео id',
            description: 'Id відео з youtube',
            align: 'center',
            headerAlign: 'center',
            flex: 4,
        },
        {
            field: 'category',
            headerName: 'Категорія',
            description: 'Категорія товару на сайті',
            align: 'center',
            headerAlign: 'center',
            flex: 4,
        },
    ];

    const notifyType = isSuccess ? 'success' : 'error';
    const notifyText = isSuccess ? 'Довар успішно додано' : 'Помилка. Зверніться до Адміністратора.';

    const onFilterChange = React.useCallback(async (filterModel: GridFilterModel) => {
        if (!filterModel.items) {
            return;
        }

        const { page, pageSize } = paginationModel;
        const response = filterModel.items[0]?.value
            ? await getFiltredProducts(filterModel, page, pageSize)
            : await getPaginatedProducts(page, pageSize);

        if (response.data) {
            const { content, number: page, size: pageSize, totalElements: totalRowCount } = response.data;
            setIsLoading(false);
            setRows(content);
            setPageInfo({ page, pageSize, totalRowCount });
            setRowCountState(totalRowCount);
        }
    }, [paginationModel.page, paginationModel.pageSize, setRowCountState]);

    return (
        <Box
            sx={{
                maxWidth: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
                wordWrap: 'break-word',
                "& .MuiDataGrid-virtualScrollerRenderZone": {
                    "& .MuiDataGrid-row": {
                        "&:nth-of-type(1n):not(.Mui-selected)": {backgroundColor: "rgba(255,255,255, .5)"},
                        "&:nth-of-type(2n):not(.Mui-selected)": {backgroundColor: "rgba(224, 224, 224, .5)"},
                        "&.Mui-selected": {backgroundColor: "rgba(231,212,24,0.72)"},
                        '&:hover:not(.Mui-selected)': {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor("rgba(255,221,2,0.57)", theme.palette.mode),
                        },
                    },
                },
                "& .MuiDataGrid-filterIcon": {
                    color: "rgba(255,255,255,1)",
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgba(52,58,64,1)",
                    color: "rgba(255,255,255,1)",
                    fontSize: 16
                },
                "& .MuiDataGrid-footerContainer": {
                    backgroundColor: "rgba(52,58,64,1)",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    "& .MuiTablePagination-selectLabel": {
                        paddingTop: 0.4
                    },
                    "& .MuiTablePagination-displayedRows": {
                        paddingTop: 0.4
                    },
                    "& .MuiDataGrid-selectedRowCount": {
                        paddingTop: 0.4,
                        color: 'white',
                    },
                    "& .MuiSelect-icon": {
                        color: 'white'
                    }
                },
                "& .MuiTablePagination-toolbar": {
                    color: 'white',
                },
                "& .MuiDataGrid-cell": {
                    border: 1,
                    borderRight: 0,
                    borderTop: 0,
                    BorderBottom: 0
                },
                "& .MuiDataGrid-sortIcon": {
                    opacity: 1,
                    color: "white",
                },
                "& .MuiDataGrid-menuIconButton": {
                    opacity: 1,
                    color: "white"
                },
            }}
        >
            <Snackbar
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                open={openNotification}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <Alert severity={notifyType} sx={{width: '100%'}} onClose={handleCloseNotification}>
                    {notifyText}
                </Alert>
            </Snackbar>

            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{flex: 1}}></div>
                <SiteProductsActions setIsNewProduct={setIsNewProduct} setIsProductMenuOpen={setIsProductMenuOpen}/>
            </div>
            <div style={{margin: "0 auto"}}>
                {(isProductMenuOpen ? <SiteProductMenu isNewProduct={isNewProduct}
                                                       product={product}
                                                       isProductMenuOpen={isProductMenuOpen}
                                                       setIsSuccess={setIsSuccess}
                                                       setOpenNotification={setOpenNotification}
                                                       setIsProductMenuOpen={setIsProductMenuOpen}/> : <div></div>)}
                <DataGrid
                    rowHeight={25}
                    rows={rows}
                    rowCount={rowCountState}
                    columns={columns}
                    loading={isLoading}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
                    onRowDoubleClick={handleOpenProductMenu}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    onFilterModelChange={onFilterChange}
                />
            </div>
        </Box>
    )
}

