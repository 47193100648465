import React from 'react';
import styles from './spinner.module.css';

const Spinner = () => {
    return (
        <div className={styles["loadingio-spinner-gear-4yff124e1s5"]}>
            <div className={styles["ldio-nn5fguxjp8"]}>
                <div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Spinner;