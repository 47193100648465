import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {SvgIconProps} from "@mui/material";
import {Link,useLocation} from "react-router-dom";

import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    position: 'relative',
    '&:hover::before, &:hover::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: '5px',
        borderRadius: '5px',
        animation: 'shimmer 2s ease-in-out infinite',
        backgroundColor: 'rgba(255, 233, 0)',
    },
    '&:hover::before': {
        animationDelay: '0.3s',
    },
    '&:hover::after': {
        animationDelay: '1.5s',
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: '10px',
            borderRadius: '5px',
            backgroundColor: 'rgba(255, 233, 0)',
        },
    },
}));

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    '&:hover': {
        color: 'white!important',
    },
}));

const StyledLine = styled('div')(({ theme }) => ({
    backgroundColor: 'yellow',
    height: '100%',
    width: '5px',
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: '10px',
    animation: 'shimmer 2s ease-in-out infinite',
}));


interface NavigationMenuItemProps {
    drawerOpen: boolean;
    text: string;
    icon: React.ReactElement<SvgIconProps>;
    subitems: { name: string; link: string; }[];
    setDrawerOpen: (open: boolean) => void;
}

export default function NavMenuItem(props: NavigationMenuItemProps) {

    const [openDropdown, setOpenDropdown] = React.useState(false);

    const location = useLocation();

    const toggleDropdown = () => {
        if (props.drawerOpen) {setOpenDropdown(!openDropdown);} else {props.setDrawerOpen(true);}
    };

    return (
        <List sx={{pt: 0, pb: 0}}>
            <StyledListItem onClick={toggleDropdown}>
                <ListItemIcon sx={{minWidth: '50px'}}>
                    {React.cloneElement(props.icon, {style: {color: 'white'}})}
                </ListItemIcon>
                <ListItemText primary={props.text} sx={{color: 'white'}}/>
                {openDropdown ? <ExpandLess sx={{color: 'white'}}/> : <ExpandMore sx={{color: 'white'}}/>}
            </StyledListItem>
            <Collapse in={props.drawerOpen ? openDropdown : false} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{color: 'white'}}>

                    {props.subitems.map((subitem) => (

                        <StyledListItem key={subitem.link} sx={{ pl: '66px', pt: 0, pb: 0 }}>
                            <StyledLine style={{ display: (subitem.link === location.pathname) ? 'block' : 'none' }} />
                            <StyledLink onClick={() => {props.setDrawerOpen(false);}} to={subitem.link}
                                  style={{color: (subitem.link === location.pathname) ? 'rgba(255, 233, 0)' : 'rgb(201,201,201)'}}
                            >
                                {subitem.name}
                            </StyledLink>
                        </StyledListItem>
                    ))}
                </List>
            </Collapse>
        </List>
    );
}